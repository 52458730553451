'use client';

import { ConnectionProvider } from '@solana/wallet-adapter-react';
import CrossChainWalletModal from '@spherelabs/react-private/components/css/CrossChainWalletModal';
import AppToastContainer from '@spherelabs/react-private/components/tailwind/AppToast';
import {
  TipLinkWalletAutoConnectV2,
  WalletModalProvider,
} from '@tiplink/wallet-adapter-react-ui';
import axios from 'axios';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import { NuqsAdapter } from 'nuqs/adapters/next';

import { publicSans } from '@/common/constants/fonts';

import { IntercomController } from '@/features/intercom/components/IntercomController';
import { IntercomProvider } from '@/features/intercom/components/IntercomProvider';
import ConfirmationDialog from '../components/ConfirmationDialog';
import clsx from '../utils/clsx';
import {
  FIREBASE_APP_NAMES,
  FirebaseAuthProvider,
} from './FirebaseAuthProvider';
import { WalletProvider } from './WalletProvider';

require('@tiplink/wallet-adapter-react-ui/styles.css');

const SuccessDialogProvider = dynamic(() => import('./SuccessDialogProvider'), {
  ssr: false,
});

const EvmWalletProvider = dynamic(
  () => import('@spherelabs/react-private/providers/EvmWalletProvider'),
  { ssr: false },
);

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string;

axios.defaults.baseURL =
  process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080';
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response?.status === 401 || error.response?.status === 403) {
//       signOutMainApp();
//       signOutCustomerApp();
//     }
//     return Promise.reject(error);
//   },
// );

export default function AppProviders({
  children,
  className,
  shouldInitializeIntercom = true,
}: {
  children?: React.ReactNode;
  className?: string;
  shouldInitializeIntercom?: boolean;
}) {
  const searchParams = useSearchParams();

  const isIFrame =
    !globalThis.window || globalThis.window !== globalThis.window.top;

  return (
    <TipLinkWalletAutoConnectV2 isReady query={searchParams}>
      {/* Both the https and wss endpoints come from our chain-stack
      projects=production and instance=frontend project. We can expose this
      project.
      TODO: set rate limits on this project. */}
      <ConnectionProvider
        endpoint='https://susan-gdzl57-fast-mainnet.helius-rpc.com'
        // endpoint='https://solana-mainnet.core.chainstack.com/ed6af817babd5c624edb26ef117f4dd3'
        config={{
          commitment: 'confirmed',
          wsEndpoint:
            'wss://solana-mainnet.core.chainstack.com/ws/ed6af817babd5c624edb26ef117f4dd3',
        }}
      >
        <WalletProvider>
          <WalletModalProvider>
            <EvmWalletProvider>
              <CrossChainWalletModal />
              <NuqsAdapter>
                <FirebaseAuthProvider appName={FIREBASE_APP_NAMES.mainApp}>
                  <IntercomProvider
                    appId={INTERCOM_APP_ID}
                    shouldInitialize={!isIFrame && shouldInitializeIntercom}
                  >
                    <IntercomController />
                    <SuccessDialogProvider>
                      <ConfirmationDialog />
                      <main
                        className={clsx(
                          'h-full',
                          publicSans.className,
                          className,
                        )}
                      >
                        {children}
                      </main>
                    </SuccessDialogProvider>
                  </IntercomProvider>
                </FirebaseAuthProvider>
              </NuqsAdapter>
            </EvmWalletProvider>
          </WalletModalProvider>
        </WalletProvider>
        <AppToastContainer />
      </ConnectionProvider>
    </TipLinkWalletAutoConnectV2>
  );
}
