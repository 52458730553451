import { IntercomMethod } from '../types';
import { isSSR } from '../utils/isSSR';
import * as IntercomModule from '@intercom/messenger-js-sdk';

const {
  Intercom: initIntercom,
  default: intercomDefault,
  ...intercomMethods
} = IntercomModule;

/**
 * Safely exposes `window.Intercom` and passes the arguments to the instance
 *
 * @param method method passed to the `window.Intercom` instance
 * @param args arguments passed to the `window.Intercom` instance
 *
 * @see {@link https://developers.intercom.com/installing-intercom/docs/intercom-javascript}
 */
const IntercomAPI = (method: IntercomMethod, ...args: Array<any>) => {
  if (!isSSR && window.Intercom) {
    return intercomMethods[method].apply(null, args);
  }

  console.error(`${method} Intercom instance is not initalized yet`);
};

export default IntercomAPI;
