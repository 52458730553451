'use client';
import { useEffect } from 'react';
import { useIntercom } from '../hooks/useIntercom';
import useSWR from 'swr';

import axios from 'axios';
import { SphereApiResponseDTO } from '@spherelabs/dtos';
import { useDefaultApplication } from '@/common/hooks/sdkHooks';
import { useUserV2 } from '@/common/hooks/useUserV2';

function IntercomControllerInner() {
  const { user } = useUserV2();
  const { isBooted, boot } = useIntercom();
  const { data: userHash } = useIntercomHash();
  const { data: application } = useDefaultApplication();

  useEffect(
    function bootIntercom() {
      if (!user) return;

      if (!isBooted && application && userHash) {
        boot({
          userId: user.uid,
          name: user.displayName ?? '',
          email: user.email ?? '',
          company: {
            companyId: application.id,
            name: application.name !== 'default' ? application.name : undefined,
          },
          customAttributes: {
            sphere_application_id: application.id,
          },
          userHash,
        });
      }
    },
    [user, isBooted, application, boot, user, userHash],
  );

  return null;
}

export function IntercomController() {
  const { user } = useUserV2();
  const { shutdown } = useIntercom();

  useEffect(
    function unmountIntercomIfNotLoggedIn() {
      if (!user) {
        return shutdown();
      }
    },
    [user, shutdown],
  );

  return !user ? null : <IntercomControllerInner />;
}

function useIntercomHash() {
  const { token } = useUserV2();
  const swr = useSWR(
    !token ? null : ['/v1/intercom/auth', token],
    async ([path, token]) => {
      const response = await axios.post<SphereApiResponseDTO<{ hash: string }>>(
        path,
        undefined,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const hash = response.data.data.hash;
      return hash;
    },
  );

  return swr;
}
