import(/* webpackMode: "eager", webpackExports: ["LinkedInScriptTag"] */ "/app/apps/frontend/src/app/[lang]/(landing)/components/LinkedInScriptTag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/frontend/src/common/providers/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/plaid/dist/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/common/constants/fonts.ts\",\"import\":\"Public_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"publicSans\"}");
