import { Chain } from 'wagmi/chains';
import { StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  createWithEqualityFn,
  UseBoundStoreWithEqualityFn,
} from 'zustand/traditional';

interface SphereCrossChainWalletModalState {
  activeEvmChain: Chain | null;
  setActiveEvmChain: (chain: Chain | null) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const useSphereCrossChainWalletModal: UseBoundStoreWithEqualityFn<
  StoreApi<SphereCrossChainWalletModalState>
> = createWithEqualityFn<SphereCrossChainWalletModalState>()(
  (set) => ({
    activeEvmChain: null,
    setActiveEvmChain: (chain) => set({ activeEvmChain: chain }),
    open: false,
    setOpen: (open) => set({ open }),
  }),
  shallow,
);
