import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@tiplink/wallet-adapter-react-ui';
import { useCallback } from 'react';
import { useDisconnect } from 'wagmi';

import { useSphereCrossChainWalletModal } from './useSphereCrossChainWalletModal';
import { useConnectEvmWallet } from '@spherelabs/async-wallet/useConnectEvmWallet';

export const useHandleCrossChainWalletConnection = () => {
  const { setVisible: setSolanaWalletModalVisible } = useWalletModal();
  const { disconnect: disconnectEvmWallet } = useDisconnect();
  const { disconnect: disconnectSolanaWallet } = useWallet();
  const { setIsCrossChainModelOpen } = useSphereCrossChainWalletModal(
    (state) => ({
      setActiveEvmChain: state.setActiveEvmChain,
      activeEvmChain: state.activeEvmChain,
      setIsCrossChainModelOpen: state.setOpen,
    }),
  );

  const handleConnectSolanaWallet = useCallback(() => {
    disconnectEvmWallet();
    disconnectSolanaWallet();
    setIsCrossChainModelOpen(false);
    setSolanaWalletModalVisible(true);
  }, [
    disconnectEvmWallet,
    disconnectSolanaWallet,
    setIsCrossChainModelOpen,
    setSolanaWalletModalVisible,
  ]);

  const handleConnectEvmWallet = useConnectEvmWallet();

  return {
    handleConnectSolanaWallet,
    handleConnectEvmWallet,
  };
};
