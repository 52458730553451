import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface ConfirmationState {
  isConfirming: boolean;
  isConfirm: boolean | null;
  content: React.ReactNode;
  setIsConfirming: (isConfirming: boolean) => void;
  setContent: (content: React.ReactNode) => void;
  setIsConfirm: (isConfirm: boolean) => void;
}

export const useConfirmationStore = createWithEqualityFn<ConfirmationState>()(
  (set) => ({
    isConfirming: false,
    isConfirm: null,
    setIsConfirming: (isConfirming) => set({ isConfirming }),
    setIsConfirm: (isConfirm) => set({ isConfirm }),
    content: null,
    setContent: (content) => set({ content }),
  }),
  shallow,
);
