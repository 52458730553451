import './CrossChainWalletModal.css';

import { isEvm, isTestnet, Network } from '@spherelabs/common';
import { useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useTokensInfos } from '../../../hooks/tokenHooks';
import { useHandleCrossChainWalletConnection } from '../../../hooks/useHandleCrossChainWalletConnection';
import { useSphereCrossChainWalletModal } from '../../../hooks/useSphereCrossChainWalletModal';
import {
  getNetworkFromWagmiChain,
  getWagmiChainFromNetwork,
} from '../../../utils/networkUtils';
import { NetworkSelectorDialog } from '../../tailwind/NetworkSelector/NetworkSelectorDialog';

export default function CrossChainWalletModal() {
  const { activeEvmChain, isCrossChainModelOpen, setIsCrossChainModelOpen } =
    useSphereCrossChainWalletModal(
      (state) => ({
        setActiveEvmChain: state.setActiveEvmChain,
        activeEvmChain: state.activeEvmChain,
        isCrossChainModelOpen: state.open,
        setIsCrossChainModelOpen: state.setOpen,
      }),
      shallow,
    );
  const activeNetwork = !activeEvmChain
    ? null
    : getNetworkFromWagmiChain(activeEvmChain);

  const { handleConnectEvmWallet, handleConnectSolanaWallet } =
    useHandleCrossChainWalletConnection();

  const tokens = useTokensInfos();
  const [shouldIncludeTestnets, setShouldIncludeTestnets] = useState(false);
  useEffect(
    function updateShouldIncludeTestnet() {
      if (activeNetwork && isTestnet(activeNetwork)) {
        setShouldIncludeTestnets(true);
      }
    },
    [activeNetwork],
  );

  const evmNetworks = useMemo(
    () =>
      Array.from(
        new Set(
          tokens
            ?.filter((token) =>
              shouldIncludeTestnets
                ? isEvm(token.network)
                : isEvm(token.network) && !isTestnet(token.network),
            )
            .map((token) => token.network as Network),
        ),
      ),
    [shouldIncludeTestnets, tokens],
  );

  const supportedNetworks = useMemo(
    () => [Network.SOL, ...evmNetworks],
    [evmNetworks],
  );

  return (
    <NetworkSelectorDialog
      open={isCrossChainModelOpen}
      supportedNetworks={supportedNetworks}
      onClose={() => {
        setIsCrossChainModelOpen(false);
      }}
      onChange={(network) => {
        if (isEvm(network)) {
          handleConnectEvmWallet(getWagmiChainFromNetwork(network).id);
        } else {
          handleConnectSolanaWallet();
        }
      }}
    />
  );
}
