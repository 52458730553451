import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { useMemo } from 'react';
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  useFirebaseApp,
} from 'reactfire';

export const FIREBASE_APP_NAMES = {
  /**
   * Main app is used for merchant auth.
   */
  mainApp: 'mainApp',

  /**
   * Customer app is used for subscription customer auth.
   */
  customerApp: 'customerApp',
};

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const FirebaseAuthProvider = ({
  children,
  appName,
}: {
  children: React.ReactNode;
  appName?: string;
}) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig} appName={appName}>
    <FirebaseAuthProviderInner>
      <FirebaseAnalyticsProviderInner>
        {children}
      </FirebaseAnalyticsProviderInner>
    </FirebaseAuthProviderInner>
  </FirebaseAppProvider>
);

const FirebaseAuthProviderInner = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const app = useFirebaseApp();
  const auth = useMemo(() => getAuth(app), [app]);

  return <AuthProvider sdk={auth}>{children}</AuthProvider>;
};

const FirebaseAnalyticsProviderInner = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const app = useFirebaseApp();
  const analytics = useMemo(() => getAnalytics(app), [app]);

  return <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>;
};
