import { BitKeepWalletAdapter } from '@solana/wallet-adapter-bitkeep';
import { BraveWalletAdapter } from '@solana/wallet-adapter-brave';
import { CloverWalletAdapter } from '@solana/wallet-adapter-clover';
import { Coin98WalletAdapter } from '@solana/wallet-adapter-coin98';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { ExodusWalletAdapter } from '@solana/wallet-adapter-exodus';
import { GlowWalletAdapter } from '@solana/wallet-adapter-glow';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';
import { MathWalletAdapter } from '@solana/wallet-adapter-mathwallet';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { WalletProvider as SolanaWalletProvider } from '@solana/wallet-adapter-react';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { SolongWalletAdapter } from '@solana/wallet-adapter-solong';
import { SpotWalletAdapter } from '@solana/wallet-adapter-spot';
import { TorusWalletAdapter } from '@solana/wallet-adapter-torus';
import { TipLinkWalletAdapter } from '@tiplink/wallet-adapter';
import React from 'react';

export const WALLETS = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new ExodusWalletAdapter(),
  new BraveWalletAdapter(),
  new CoinbaseWalletAdapter(),
  new TorusWalletAdapter(),
  new CloverWalletAdapter(),
  new Coin98WalletAdapter(),
  new LedgerWalletAdapter(),
  new SolongWalletAdapter(),
  new MathWalletAdapter(),
  new GlowWalletAdapter(),
  new SpotWalletAdapter(),
  new BitKeepWalletAdapter(),
  new TipLinkWalletAdapter({
    title: 'Sphere',
    clientId: '818bbc4f-2c56-4c24-903e-f50f0b0628bf',
    theme: 'light',
  }),
];

export function WalletProvider({ children }: { children: React.ReactNode }) {
  return (
    <SolanaWalletProvider wallets={WALLETS} autoConnect>
      {children}
    </SolanaWalletProvider>
  );
}
