import { useContext } from 'react';
import { IntercomContext } from '../components/IntercomProvider';

export const useIntercom = () => {
  const context = useContext(IntercomContext);

  if (context === undefined) {
    throw new Error('"useIntercom" must be used within `IntercomProvider`.');
  }

  return context;
};
