import {
  SphereButton,
  SphereDialog,
  Typography,
} from '@spherelabs/react-private';
import { useCallback } from 'react';

import { useConfirmationStore } from './useConfirmationStore';

export default function ConfirmationDialog() {
  const { isConfirming, content, setIsConfirm, setIsConfirming, setContent } =
    useConfirmationStore((state) => ({
      isConfirming: state.isConfirming,
      content: state.content,
      setIsConfirm: state.setIsConfirm,
      setIsConfirming: state.setIsConfirming,
      setContent: state.setContent,
    }));

  const onClose = useCallback(() => {
    setIsConfirming(false);

    // reset content only after modal closes
    setTimeout(() => {
      setContent(null);
    }, 300);
  }, [setContent, setIsConfirming]);

  return (
    <SphereDialog open={isConfirming}>
      {content}
      <div className='mt-4 flex justify-end gap-2'>
        <SphereButton
          variant='secondary'
          onClick={() => {
            setIsConfirm(false);
            onClose();
          }}
          className='!w-1/3 flex-none'
        >
          Cancel
        </SphereButton>
        <SphereButton
          onClick={() => {
            setIsConfirm(true);
            onClose();
          }}
          className='flex-grow'
        >
          Confirm
        </SphereButton>
      </div>
    </SphereDialog>
  );
}
