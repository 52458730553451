import IntercomAPI from './intercomApi';

export function reset() {
  if (window.Intercom) {
    IntercomAPI('shutdown');
    window.Intercom = undefined;
  }

  window.intercomSettings = undefined;
}
