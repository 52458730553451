/**
 * useUserV2 Hook
 *
 * This V2 hook improves readability, and removes the misleading
 * type assertion `const user = useFirebaseUser().data as User;`.
 * Key improvements include modularized logic, and simplified conditionals.
 */

import { type User, onAuthStateChanged } from 'firebase/auth';
import { useCallback } from 'react';
import { useAuth, useUser as useFirebaseUser } from 'reactfire';
import useSWR from 'swr';
import { decodeJwt } from 'jose';

/**
 * Retrieves the authentication token for the current user.
 * @param user - The current Firebase user.
 * @param forceRefresh - Whether to force a refresh of the token.
 * @returns The authentication token.
 */
const getAuthToken = async (
  user: User,
  forceRefresh = false,
): Promise<string> => {
  try {
    return await user.getIdToken(forceRefresh);
  } catch (error) {
    console.error('Error fetching token:', error);
    return '';
  }
};

/**
 * Determines if the token should be refreshed based on its expiry.
 * @param token - The current authentication token.
 * @returns Whether the token should be refreshed.
 */
const shouldRefreshToken = (token: string): boolean => {
  const decodedToken = decodeJwt(token);
  const expirationTime = decodedToken.exp ? decodedToken.exp * 1000 : 0;
  const refreshTime = expirationTime - 10 * 60 * 1000; // 10 minutes before expiry

  return !decodedToken.exp || refreshTime < Date.now();
};

/**
 * Custom hook to fetch the authentication token.
 * @param auth - The Firebase Auth instance.
 * @param user - The current Firebase user.
 * @returns A function to fetch the authentication token.
 */
const useFetchAuthToken = (auth: ReturnType<typeof useAuth>) => {
  return useCallback(() => {
    return new Promise<string>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        async (user) => {
          unsubscribe();

          if (!user) {
            resolve('');
            return;
          }

          const token = await getAuthToken(user);
          if (shouldRefreshToken(token)) {
            const refreshedToken = await getAuthToken(user, true);
            resolve(refreshedToken);
          } else {
            resolve(token);
          }
        },
        reject,
      );
    });
  }, [auth]);
};

/**
 * Custom hook to manage user authentication and token retrieval.
 * @returns The current user and authentication token.
 */
export const useUserV2 = () => {
  const user = useFirebaseUser().data;
  const auth = useAuth();
  const fetchAuthToken = useFetchAuthToken(auth);

  const { data: token } = useSWR(
    user ? ['refreshAuthToken', user.uid] : null,
    fetchAuthToken,
    { refreshInterval: 55 * 60 * 1000 }, // Refresh token every 55 minutes
  );

  return { user, token: token ?? '' };
};
